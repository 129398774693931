import React from 'react';
import classNames from 'classnames';
import {motion} from 'framer-motion';
import {divVariants} from '../../styles/framerMotionVariants';
import './StemmyPurpleButton.scoped.css';
import {DialogAnswer} from '../../../pages/dialogs/StemmyDialogsPage';

export interface StemmyPurpleButtonProps {
  answer: DialogAnswer;
  isAnswerDone?: boolean;
  isSelectedAnswer?: boolean;
  onClick: (answer: string) => void;
}

const StemmyPurpleButton: React.FC<StemmyPurpleButtonProps> = ({
  answer,
  isAnswerDone = false,
  isSelectedAnswer = false,
  onClick,
}) => {
  return (
    <motion.button
      initial={'hidden'}
      animate={'visible'}
      transition={{duration: 0.25}}
      variants={divVariants}
      disabled={isAnswerDone}
      className={classNames(
        'px-3 py-3 text-white font-bold text-center rounded-md cursor-pointer w-full sm:w-2/3 flex justify-center items-center relative overflow-hidden button-purple',
        isAnswerDone &&
          isSelectedAnswer &&
          'button-purple-clicked answer-clicked-shadow',
      )}
      onClick={onClick ? () => onClick(answer.answerId) : () => {}}
    >
      <div
        className={classNames(
          'absolute w-full h-full upper-button -top-1/2 opacity-20',
          isAnswerDone && isSelectedAnswer && 'upper-button-clicked',
        )}
      />
      <span className="z-10 webkit-box-max-2-line text-game-lg">
        {answer.answer}
      </span>
    </motion.button>
  );
};
export default StemmyPurpleButton;
