import {Canvas} from '@react-three/fiber';
import {useCallback, useEffect, useRef, useState} from 'react';
import React from 'react';
import JetpackCharacter, {
  JetpackCharacterPropsRefProps,
} from '../jetpack/Jetpack';
import DialogBubblePopup from '../../../../components/game/mechanics/dialogBubblePopup/DialogBubblePopup';
import {Vector3} from 'three';
import {ScriptGameCommunicator} from '../jetpack/JetpackWrapper';

// interface ScriptGameCommunicator<T> {
//   target: string;
//   element: string;

//   event: T;
// }

interface TalkingGettingEvent {
  event: 'end';
}

interface TalkingSendingEvent {
  event: 'start' | 'achievement';
}

const TalkingWrapper: React.FC = () => {
  const characterRef =
    useRef<JetpackCharacterPropsRefProps>() as React.MutableRefObject<JetpackCharacterPropsRefProps>;

  const [isStarted, setIsStarted] = useState(false);

  useEffect(() => {
    const onMessage = (event: {
      data: ScriptGameCommunicator<TalkingGettingEvent>;
    }) => {
      console.log('got a message');
      if (
        event.data.target === 'stemmy-iframe' &&
        event.data.element === 'talking'
      ) {
        if (event.data.event.event === 'end') {
          characterRef.current.land();

          //DELETE ME
          setTimeout(() => {
            characterRef.current.dance();
          }, 1000);
        }
      }
    };

    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  const sendEvent = useCallback((event: TalkingSendingEvent) => {
    const data: ScriptGameCommunicator<TalkingSendingEvent> = {
      target: 'stemmy-script',
      element: 'talking',
      event,
    };
    parent.postMessage(data, '*');
  }, []);

  const [isShown, setIsShown] = useState(true);

  return (
    <div
      className="w-screen relative aspect-square"
      onClick={() => {
        if (isStarted) return;
        setIsStarted(true);

        characterRef.current.speak();
        // characterRef.current.takeoff();
        // sendEvent({
        //   event: 'start',
        // });
      }}
    >
      <Canvas
        shadows
        camera={{rotation: [0, 0, 0], position: [0, 1.43, 5], fov: 35}}
      >
        <ambientLight color="#fff" intensity={0.3} />
        <pointLight color="#fff" position={[0, 5, 5]} />
        <JetpackCharacter
          ref={characterRef}
          position={new Vector3(-0.3, 0, 0)}
        />
      </Canvas>

      {isShown && (
        <DialogBubblePopup
          className="absolute max-w-[60%] top-[5%] right-[2%]"
          prefixId="dialogBubblePopup"
          id="dialogBubblePopup"
          active={isShown}
          onFinish={() => {
            setIsShown(false);

            characterRef.current.takeoff();
            sendEvent({
              event: 'start',
            });
          }}
          elements={[
            {
              id: '39C8528F-0891-4DCF-917E-5F57A09A6CF5',
              title: 'Hey! Can I help you?',
              innerButtons: [
                {
                  id: '4569D2EE-AF1C-4C74-93DC-C8A5C84CAE6A',
                  title: 'Yes',
                  onClick: () => console.log('Ok clicked'),
                  isNextOnClick: true,
                  className: 'w-full',
                },
              ],
            },
            {
              id: '5BC8C11E-7A3F-4E19-9E02-CD6D14C45ECE',
              title: 'What do you want to learn?',
              innerButtons: [
                {
                  id: '4569D2EE-AF1C-4C74-93DC-C8A5C84CAE6A',
                  title: 'Cryptoys',
                  onClick: () => {
                    sendEvent({
                      event: 'achievement',
                    });
                  },
                  isNextOnClick: true,
                  className: 'w-full',
                },
                {
                  id: 'B70315C9-4A00-46AE-A134-8C9AE762991F',
                  title: 'NFTs',
                  onClick: () => {
                    sendEvent({
                      event: 'achievement',
                    });
                  },
                  isNextOnClick: true,
                  className: 'w-full',
                },
              ],
            },
            {
              id: 'B0D2C2BF-737D-4C20-97FA-1636876A65F5',
              title: 'Quickly! It is escaping! Guide me to the tab!',
              innerButtons: [
                {
                  id: 'AE73F9EA-55AB-437A-B502-8B425312FB7D',
                  title: 'Lets go!',
                  onClick: () => console.log('Ok clicked'),
                  isNextOnClick: true,
                  className: 'w-full',
                },
              ],
            },
          ]}
        />
      )}

      {/* <div className="flex gap-w">
        <Button
          onClick={() => {
            parent.postMessage(
              {
                target: 'stemmy-script',
                element: 'jetpack',
              },
              '*',
            );
          }}
        >
          Test
        </Button>
        <Button
          onClick={() => {
            characterRef.current.speak();
          }}
        >
          Speak
        </Button>
        <Button
          onClick={() => {
            characterRef.current.takeoff();
          }}
        >
          takeoff
        </Button>
        <Button
          onClick={() => {
            characterRef.current.land();
          }}
        >
          land
        </Button>
        <Button
          onClick={() => {
            characterRef.current.dance();
          }}
        >
          dance
        </Button>
      </div> */}
    </div>
  );
};

export default TalkingWrapper;
