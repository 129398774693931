import classNames from 'classnames';
import mixpanel from 'mixpanel-browser';
import {useCallback, useEffect, useState} from 'react';
import DialogBubble, {DialogBubbleAnimation} from '../../utils/DialogBubble';
import {GamePopupFillProps} from '../InformingSliderView/GamePopupElement';

export interface DialogBubblePopupProps
  extends React.HTMLProps<HTMLDivElement> {
  prefixId: string;

  elements: GamePopupFillProps[];
  active?: boolean;

  onStart?: () => void;
  onFinish?: () => void;

  tempHidden?: boolean;
}

const DialogBubblePopup: React.FC<DialogBubblePopupProps> = ({
  id,
  className,
  prefixId,
  elements,
  onStart,
  onFinish,
  active = true,
  tempHidden = false,
}) => {
  const [popupId] = useState(`${prefixId}.${id}`); // получается текстовый айди вида "intro.intro" или "intro.phone_form"

  const [animation, setAnimation] = useState<DialogBubbleAnimation>(
    DialogBubbleAnimation.Initial,
  ); // номер анимации, какую анимацию использовать для появления

  const [startTriggered, setStartTriggered] = useState(false); // сработало ли первоначальное onStart

  useEffect(() => {
    if (!startTriggered && active) {
      setStartTriggered(true);
      console.log(`STATISTICS: launched popup: "${popupId}"`);

      // mixpanel.track('Popup launch', {
      //   prefix: prefixId,
      //   id: id,
      // });

      if (onStart) onStart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]); // сработает только в первый раз когда active станет true, потом startTriggered уже тоже будет true и не даст запустить этот эффект. Но когда active станет false, startTriggered тоже переключит в false и можно по новой

  useEffect(() => {
    if (!active) setStartTriggered(false);
  }, [active]); // когда мы переключаемся на другой currectStage, active становится false и, соответственно, startTriggered

  const [currentElementIndex, setCurrentElementIndex] = useState(0); // элементы это своего рода набор всяких кнопок/заголовков в виде объекта. Нам присылается массив таких объектов (elements) и мы их поочереди отрисовываем. Тут храним какой рисуем. Это не currectStage, элементы это то, что внутри каждого currectStage

  const [fallback, setFallback] = useState<GamePopupFillProps>(); // не знаю зачем это, оставил на всякий случай

  useEffect(() => {
    if (tempHidden) setAnimation(DialogBubbleAnimation.Hidden);
    else setAnimation(DialogBubbleAnimation.Initial);
  }, [tempHidden]); // tempHidden это флаг по которому мы можем удалить элемент (display: none)

  const OnFinishAnimation = useCallback(() => {
    if (onFinish) onFinish();
  }, [onFinish]); // не знаю зачем отдельную ф-ию создавать, тем более название OnFinishAnimation сбивает с толку, но она вызывается после нажатия кнопки на последнем этапе, перед переключением на новый. Это вовсе не та ф-ия, которая отслеживает время жизни анимации и в конце срабатывает)

  const OnNextAnimation = useCallback(() => {
    console.log('On next animation');
    if (currentElementIndex + 1 >= elements.length) {
      OnFinishAnimation();
      return;
    }

    setAnimation(DialogBubbleAnimation.Next);
    setCurrentElementIndex((x) => {
      const index = x + 1;
      return index;
    });
  }, [OnFinishAnimation, currentElementIndex, elements.length]); // переключаемся на след. элемент

  const OnBackAnimation = useCallback(() => {
    if (currentElementIndex <= 0) return;

    setAnimation(DialogBubbleAnimation.Back);
    setCurrentElementIndex((x) => {
      const index = x - 1;
      return index;
    });
  }, [currentElementIndex]); // переключаемся на пред. элемент

  const OnFallback = useCallback(() => {
    if (
      elements[currentElementIndex] &&
      elements[currentElementIndex].fallback
    ) {
      setTimeout(
        () => setFallback(elements[currentElementIndex].fallback),
        200,
      );
    }
  }, [currentElementIndex, elements]);

  const OnJumpToSlide = useCallback(
    (val: number) => {
      if (val < 0 || val >= elements.length) return;

      if (currentElementIndex < val) setAnimation(DialogBubbleAnimation.Next);
      else setAnimation(DialogBubbleAnimation.Back);
      setCurrentElementIndex(() => {
        const index = val;
        return index;
      });
    },
    [currentElementIndex, elements.length],
  ); // мы можем прыгнуть на любой элемент, не обязательно только вперед/назад (+1/-1)

  if (!elements[currentElementIndex]) return <div>No current element!</div>;

  return (
    <div
      className={classNames(
        (!active || animation === DialogBubbleAnimation.Hidden) && 'hidden',
        className,
      )}
    >
      <DialogBubble
        elementId={elements[currentElementIndex].id}
        prefixId={popupId}
        onFinish={OnFinishAnimation}
        onFallback={OnFallback}
        onBack={OnBackAnimation}
        onNext={OnNextAnimation}
        onJumpToSlide={OnJumpToSlide}
        question={elements[currentElementIndex].title}
        buttons={elements[currentElementIndex].innerButtons}
        animation={animation}
      />
    </div>
  );
};

export default DialogBubblePopup;
