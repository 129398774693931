import './RegistrationCard.scoped.css';

export interface RegistrationInputProps {
  width?: string;
  height?: string;
  top?: string;
  left?: string;
  title?: string;
  type?: string;
  value?: string;
  onChange?: (text: string) => void;
}

const RegistrationInput: React.FC<RegistrationInputProps> = ({
  width = '10%',
  height = '10%',
  top = '10%',
  left = '10%',
  title = 'NAME',
  type = 'text',
  value = '',
  onChange = () => {},
}) => {
  return (
    <div
      className={`absolute purple-bg-registrationCard-element w-[${width}] h-[${height}] top-[${top}] left-[${left}] overflow-hidden`}
    >
      <div className="relative w-full h-full">
        <div className="absolute top-0 left-[6%] text-game-xs font-sigmarOne">
          {title}
        </div>
        <input
          type={type}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="bg-transparent px-4 pt-2 w-full h-full border-0 font-sigmarOne text-game-sm relative z-10"
        />
      </div>
    </div>
  );
};
export default RegistrationInput;
