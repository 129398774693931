import classNames from 'classnames';
import mixpanel from 'mixpanel-browser';
import {useState} from 'react';
import Button from '../../../form/button/Button';
import {PopupButton} from './GamePopupElement';

interface ButtonsListProps {
  prefixId: string;
  buttons: PopupButton[];

  onBack: () => void;
  onNext: () => void;
  onFinish: () => void;
  onFallback: () => void;
  onJumpToSlide: (val: number) => void;
}

const ButtonsList: React.FC<ButtonsListProps> = ({
  prefixId,
  buttons,
  onBack,
  onNext,
  onFinish,
  onFallback,
  onJumpToSlide,
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState<number>();
  return (
    <>
      {buttons.map((x, index) => (
        <Button
          key={index}
          disableHover={selectedAnswer !== undefined}
          buttonStyle={x.buttonStyle}
          className={classNames(
            x.className,
            selectedAnswer === index &&
              (x.isCoorrectAnswer === true
                ? 'correct-answer-shadow'
                : 'wrong-answer-shadow'),
            selectedAnswer !== undefined &&
              (x.isCoorrectAnswer === true ? 'correct-answer' : 'wrong-answer'),
          )}
          disabled={!!selectedAnswer}
          onClick={() => {
            if (x.isCoorrectAnswer !== undefined) {
              setSelectedAnswer(index);
            }
            console.log(
              `STATISTICS: Button press event: "${prefixId}.${x.id}"`,
            );
            // mixpanel.track('Game button press', {
            //   prefix: prefixId,
            //   id: x.id,
            // });

            setTimeout(() => {
              if (x.isBackOnClick) onBack();
              if (x.isNextOnClick) onNext();
              if (x.isFinishOnClick) onFinish();
              if (x.isFallbackOnClick) onFallback();
              if (x.jumpToIndexOnClick !== undefined) {
                onJumpToSlide(x.jumpToIndexOnClick);
              }
              if (x.onClick) {
                x.onClick(x.id);
              }
            }, x.clickDelay || 0);
          }}
        >
          {x.title}
        </Button>
      ))}
    </>
  );
};

export default ButtonsList;
