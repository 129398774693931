import {QueryClient} from '@tanstack/react-query';
import {PersistQueryClientProvider} from '@tanstack/react-query-persist-client';
import {createSyncStoragePersister} from '@tanstack/query-sync-storage-persister';
import 'react-notifications-component/dist/theme.css';
import AppRouter from './router/Router';
import './locales/i18n';
import {CookieStorage} from 'cookie-storage';
import mixpanel from 'mixpanel-browser';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const cookieStorage = new CookieStorage({
  sameSite: 'None',
  secure: true,
});
const persister = createSyncStoragePersister({
  storage: cookieStorage,
});

mixpanel.init('69a08601034386a14a7b8582e39f3fe3', {
  debug: false,
  ignore_dnt: true,
});

const App: React.FC = () => {
  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{persister}}
    >
      <AppRouter />
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </PersistQueryClientProvider>
  );
};

export default App;
