import {useState} from 'react';
import {BasicElement} from '../../helpers/BasicElement';
import {AiOutlineClose} from 'react-icons/ai';
import {AnimatePresence, motion} from 'framer-motion';
import {divVariants} from '../styles/framerMotionVariants';

export interface InfoPopupAlertProps extends BasicElement {}

const InfoPopupAlert: React.FC<InfoPopupAlertProps> = ({children}) => {
  const [openPopup, setOpenPopup] = useState(false);

  return (
    <AnimatePresence>
      {openPopup && (
        <motion.div
          key="modal"
          className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-10"
          variants={divVariants}
          initial={'hidden'}
          animate={'visible'}
          exit={{
            opacity: 0,
            scaleX: ['100%', '120%', '0%'],
          }}
          transition={{duration: 0.25}}
          onClick={() => {
            setOpenPopup(!openPopup);
          }}
        >
          <div className="relative min-w-[300px] max-w-[90%] px-4 sm:px-14 py-4 text-2xl bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 rounded-md text-white text-center z-20 cursor-default mx-auto font-roboto font-bold flex justify-center items-center">
            <div className="absolute right-1 top-1">
              <AiOutlineClose
                className="cursor-pointer"
                onClick={() => setOpenPopup(false)}
              />
            </div>
            <div className="text-game-base">{children}</div>
          </div>
        </motion.div>
      )}
      <sup
        className="cursor-pointer mx-2"
        onClick={() => setOpenPopup(!openPopup)}
      >
        ?
      </sup>
    </AnimatePresence>
  );
};

export default InfoPopupAlert;
