import React, {useCallback, useState} from 'react';
import {motion} from 'framer-motion';
import './StemmyInformingField.scoped.css';
import StemmyYellowButton from '../../buttons/yellowShort/StemmyYellowButton';

export interface StemmyInformingFieldProps {
  title?: string;
  altTitle?: string;
  description?: string;
  children?: React.ReactNode;
  animation?: string;
  textField?: boolean;
  onClickTextField?: (text: string) => void;
}

const StemmyInformingField: React.FC<StemmyInformingFieldProps> = ({
  title,
  altTitle,
  description,
  children,
  animation,
  textField = false,
  onClickTextField = () => {},
}) => {
  const [value, setValue] = useState('');

  const variants = {
    initialHidden: {opacity: 0},
    initialVisible: {
      opacity: 1,
      scaleX: ['80%', '105%', '100%'],
      scaleY: ['90%', '105%', '100%'],
    },
    backVisible: {opacity: 1, x: ['-100%', '5%', '0%']},
    nextVisible: {opacity: 1, x: ['100%', '-5%', '0%']},
  };

  const onChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    },
    [],
  );

  return (
    <motion.div
      key={Date.now()}
      initial={'initialHidden'}
      animate={
        animation === 'back'
          ? 'backVisible'
          : animation === 'next'
          ? 'nextVisible'
          : 'initialVisible'
      }
      variants={variants}
      transition={{duration: 0.5}}
      className="rounded-lg px-4 sm:px-14 pt-4 pb-2 text-2xl bg-purple-gradient text-white text-center cursor-default mx-auto font-roboto font-bold relative h-full flex flex-col gap-0 sm:gap-3 justify-between items-center"
    >
      <div className="absolute top-0 left-0 w-8 lg:w-14 w-8 lg:h-14">
        <img
          src="/stemmy/e_head.png"
          className="absolute w-14 -top-1/2 -left-1/2"
        />
      </div>
      <div className="flex flex-col w-full text-game-base">
        <div className="flex gap-2 w-full">
          {altTitle && <span className="flex-1">{altTitle}</span>}
          {title && <span className="flex-1">{title}</span>}
        </div>
        {description && <div>{description}</div>}
        {textField && (
          <div className="flex justify-between items-center gap-2 flex-row w-full">
            <input
              id={title}
              className="text-black textFieldStemmy font-roboto font-medium px-4 w-full"
              onChange={(e) => onChangeInput(e)}
              type="text"
            />
            <StemmyYellowButton
              answer="Ok"
              onClick={() => onClickTextField(value)}
            />
          </div>
        )}
      </div>
      {children}
    </motion.div>
  );
};
export default StemmyInformingField;
