import {Routes, Route, BrowserRouter} from 'react-router-dom';
import HereIntegrationWrapper from '../../pages/hereIntegration/HereIntegrationWrapper';
import IntegrationDemoWrapper from '../../pages/hereIntegration/IntegrationDemoWrapper';
import Logout from '../../pages/Logout';
import Achievement from '../../pages/scriptIntegrations/achievement/Achievement';
import JetpackWrapper from '../../pages/scriptIntegrations/character/jetpack/JetpackWrapper';
import SampleAnimationTest from '../../pages/scriptIntegrations/character/sample/SampleAnimationTest';
import TalkingWrapper from '../../pages/scriptIntegrations/character/talking/TalkingWrapper';
import ScriptChestScene from '../../pages/scriptIntegrations/chest/ChestOpenScene';
import FootballMinigameScene from '../../pages/scriptIntegrations/football/Football';
import UiExamples from '../../pages/uiExamples/UiExamples';
import SuspenseLayout from '../layouts/basicLayout/SuspenseLayout';

const AppRouter: React.FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/">
        <Route
          element={<SuspenseLayout background='url("/backgrounds/2.jpg")' />}
        >
          <Route index element={<HereIntegrationWrapper />} />
          <Route
            path="/demo/chest"
            element={
              <IntegrationDemoWrapper
                loadData={{
                  scene: 3,
                }}
              />
            }
          />
          <Route
            path="/demo/constructor"
            element={
              <IntegrationDemoWrapper
                loadData={{
                  scene: 4,
                }}
              />
            }
          />
          <Route
            path="/demo/tournament"
            element={
              <IntegrationDemoWrapper
                loadData={{
                  scene: 6,
                }}
              />
            }
          />
          <Route
            path="/demo/onboarding"
            element={
              <IntegrationDemoWrapper
                loadData={{
                  scene: 10,
                }}
              />
            }
          />
          <Route path="/testintegration" element={<HereIntegrationWrapper />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/uiExample" element={<UiExamples />} />
        </Route>
      </Route>

      <Route path="/components">
        <Route element={<SuspenseLayout />}>
          <Route path="/components/chest" element={<ScriptChestScene />} />
          <Route path="/components/jetpack" element={<JetpackWrapper />} />
          <Route path="/components/speaker" element={<TalkingWrapper />} />
          <Route path="/components/achievement" element={<Achievement />} />
          <Route path="/components/test" element={<SampleAnimationTest />} />
          <Route
            path="/components/football"
            element={<FootballMinigameScene />}
          />
        </Route>
      </Route>
    </Routes>
  </BrowserRouter>
);
export default AppRouter;
