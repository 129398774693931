import {useGLTF} from '@react-three/drei';
import {Canvas} from '@react-three/fiber';
import {useState} from 'react';
import {useRef} from 'react';
import * as THREE from 'three';
import Button from '../../../../components/form/button/Button';
import {BasicBodyModel} from '../../../../types/models/v2/bodies/BasicBody';
import {BasicHeadModel} from '../../../../types/models/v2/heads/BasicHead';
import {InstantFadeAnimation} from '../../../../components/models/animations/AnimationFadePrefabs';
import AnimationLoader, {
  AnimationLoaderRefProps,
} from '../../../../components/models/animations/AnimationLoader';
import {RepeatOnceAnimation} from '../../../../components/models/animations/AnimationRepeatPrefabs';
import JetpackCharacter from '../../../../components/models/bases/JetpackCharacter';
import {JetpackAnimation} from '../../../../types/animations/JetpackAnimation';
import SmokeEffect, {
  SmokeEffectRefProps,
} from '../../../../components/models/effects/particle/smoke/SmokeEffect';
import {Vector3} from 'three';

const SampleAnimationTest: React.FC = () => {
  const animatorRef = useRef<AnimationLoaderRefProps<JetpackAnimation>>(null);

  const smokeRef = useRef<SmokeEffectRefProps>(null);

  const Head = useGLTF(
    '/models/v2/heads/Head1.glb',
  ) as unknown as BasicHeadModel;
  const Body = useGLTF(
    '/models/v2/bodies/Body1.glb',
  ) as unknown as BasicBodyModel;

  const anims1 = useGLTF('/models/v2/animations/JetPackAnimations.glb')
    .animations as JetpackAnimation[];

  const [characterGroup, setCharacterGroup] = useState<THREE.Group>();

  return (
    <div className="h-screen">
      <Canvas
        shadows
        camera={{rotation: [0, 0, 0], position: [0, 1.15, 5], fov: 28}}
      >
        <ambientLight color="#fff" intensity={0.3} />
        <pointLight color="#fff" position={[0, 5, 5]} />

        <JetpackCharacter
          headModel={Head}
          bodyModel={Body}
          onLoaded={(grp) => setCharacterGroup(grp)}
        />
        <SmokeEffect position={new Vector3(0.0, 0.3, 1)} ref={smokeRef} />

        {characterGroup && (
          <AnimationLoader
            group={characterGroup}
            animations={anims1}
            myRef={animatorRef}
            onAnimationFinished={(name) => {
              if (name == 'Flying') smokeRef.current?.diactivateSmoke();
            }}
            onAnimationStarted={(name) => {
              switch (name) {
                case 'Flying':
                  smokeRef.current?.activateSmoke();
              }
            }}
          />
        )}
      </Canvas>

      {characterGroup && (
        <div className="absolute bottom-0 flex">
          <div className="">
            <Button
              onClick={() => {
                smokeRef?.current?.activateSmoke();
              }}
            >
              Smoke
            </Button>

            <Button
              onClick={() => {
                animatorRef.current?.animate('IDLE');
              }}
            >
              Idle
            </Button>

            <Button
              onClick={() => {
                animatorRef.current?.animate('HandShake1');
              }}
            >
              Handshake
            </Button>

            <Button
              onClick={() => {
                animatorRef.current?.animate('Flying');
              }}
            >
              Flying
            </Button>
          </div>
          <div className="">
            <Button
              onClick={() => {
                animatorRef.current?.animate(
                  'IDLE',
                  RepeatOnceAnimation,
                  InstantFadeAnimation,
                );
              }}
            >
              Idle
            </Button>

            <Button
              onClick={() => {
                animatorRef.current?.animate(
                  'HandShake1',
                  RepeatOnceAnimation,
                  InstantFadeAnimation,
                );
              }}
            >
              Handshake
            </Button>

            <Button
              onClick={() => {
                animatorRef.current?.animate(
                  'Flying',
                  RepeatOnceAnimation,
                  InstantFadeAnimation,
                );
              }}
            >
              Flying
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SampleAnimationTest;
