import {animated, config, useSpring} from '@react-spring/three';
import {Canvas} from '@react-three/fiber';
import {useRef, useState, useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Vector3, Euler, BackSide} from 'three';
import {TestStatusRequestDTO} from '../../../api/hereIntegration/dto/TestStatusRequest';
import {TestStatusResponseDTO} from '../../../api/hereIntegration/dto/TestStatusResponse';
import {useCheckQuest} from '../../../api/hereIntegration/HereIntegration';
import {
  useGameAuthData,
  useUpdateGameAuthData,
} from '../../../services/gameAuth/AuthProvider';
import {BodyModel} from '../../../types/models/BodyModel';
import {HeadModel} from '../../../types/models/HeadModel';
import {HereIntegrationSave} from '../../../types/save/HereIntegrationSave';
import Button, {ButtonStyle} from '../../form/button/Button';
import Input from '../../form/input/Input';
import HitEffect, {HitEffectRefProps} from '../effects/HitEffect';
import GameSound, {GameSoundRefProps} from '../effects/Sound';
import AndroidPhone from '../mechanics/androidEmulator/AndroidPhone';
import GamePopup from '../mechanics/InformingSliderView/GamePopup';
import {
  ButtonsAlignment,
  ButtonsLayout,
  WidnowSize,
} from '../mechanics/InformingSliderView/GamePopupElement';
import InformingView from '../mechanics/InformingSliderView/InformingView';
import LeaderboardPopup from '../mechanics/leaderboardPopup/LeaderboardPopup';
import Character, {CharacterRefProps} from '../models/Character';
import Enimy, {EnimyRefProps} from '../models/Enimy';
import Fireball, {FireballRefProps} from '../models/Fireball';
import BattleInfo, {BattleInfoRefProps, Orientation} from '../ui/BattleInfo';
import BlackBackground from '../utils/BlackBackground';
import {IntegrationSceneProps} from '../utils/IntegrationScene';

interface Scene1Props extends IntegrationSceneProps<HereIntegrationSave> {
  headModel?: HeadModel;
  bodyModel?: BodyModel;
}

export interface UsernameCheckChallenge {
  nearUserName: string;
}

const HerePart1: React.FC<Scene1Props> = ({
  headModel,
  bodyModel,
  switchScene,
  prefixId = 'scene_not_set',
}) => {
  const {t} = useTranslation(['herePart1', 'universal']);

  const getAuth = useGameAuthData();
  const updateAuth = useUpdateGameAuthData();

  const fireballSoundRef = useRef<GameSoundRefProps>(null);

  const bgFightAudio = useRef<GameSoundRefProps>(null);
  const bgNormalAudio = useRef<GameSoundRefProps>(null);

  const successSound = useRef<GameSoundRefProps>(null);
  const wrongSound = useRef<GameSoundRefProps>(null);

  const playerRef =
    useRef<CharacterRefProps>() as React.MutableRefObject<CharacterRefProps>;

  const enimyRef =
    useRef<EnimyRefProps>() as React.MutableRefObject<EnimyRefProps>;

  const hitPlayerEffectRef =
    useRef<HitEffectRefProps>() as React.MutableRefObject<HitEffectRefProps>;

  const hitEnimyEffectRef =
    useRef<HitEffectRefProps>() as React.MutableRefObject<HitEffectRefProps>;

  const fireballAnimation =
    useRef<FireballRefProps>() as React.MutableRefObject<FireballRefProps>;

  const PlayerBattleInfo =
    useRef<BattleInfoRefProps>() as React.MutableRefObject<BattleInfoRefProps>;
  const EnimyBattleInfo =
    useRef<BattleInfoRefProps>() as React.MutableRefObject<BattleInfoRefProps>;

  const [isQuestionsHidden, setIsQuestionsHidden] = useState(false);
  const [currectStage, setCurrectStage] = useState(-1);

  const [isLaunched, setIsLaunched] = useState(false);
  const [isFireballVisible, setIsFireballVisible] = useState(false);

  const {fireballPosition} = useSpring({
    fireballPosition: !isLaunched ? -2.5 : 3,
    config: config.stiff,
  });

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<UsernameCheckChallenge>({
    defaultValues: {
      nearUserName: 'example.near',
    },
  });

  const castSpellAnimation = useCallback(() => {
    setIsFireballVisible(true);
    fireballAnimation.current?.spawn();
    fireballSoundRef.current?.play();
    setTimeout(() => {
      setIsLaunched(true);
    }, 2500);
    setTimeout(() => {
      setIsFireballVisible(false);
      setIsLaunched(false);

      enimyRef.current?.getDamage();
      hitEnimyEffectRef.current?.hitAnimation();
    }, 2800);
  }, [enimyRef, fireballAnimation, hitEnimyEffectRef]);

  const [question1CorrectAnswers, setQuestion1CorrectAnswers] = useState(0);

  const onQuestionAnswered = useCallback(
    (isCorrect: boolean, onCompleted?: () => void) => {
      if (isCorrect) {
        setQuestion1CorrectAnswers((x) => x + 1);
        successSound.current?.play();
      } else {
        wrongSound.current?.play();
      }

      setIsQuestionsHidden(true);
      setTimeout(() => {
        if (isCorrect) {
          playerRef.current?.attack();
          castSpellAnimation();

          PlayerBattleInfo.current?.changeEnergy(-1);

          setTimeout(() => {
            EnimyBattleInfo.current?.changeHealth(-1);
          }, 3000);

          setTimeout(() => {
            setIsQuestionsHidden(false);
            if (onCompleted) onCompleted();
          }, 4000);
        } else {
          enimyRef.current?.attack();

          EnimyBattleInfo.current?.changeEnergy(-1);

          setTimeout(() => {
            playerRef.current?.getDamage();
            hitPlayerEffectRef.current?.hitAnimation();
            PlayerBattleInfo.current.changeHealth(-1);

            setTimeout(() => {
              setIsQuestionsHidden(false);
              if (onCompleted) onCompleted();
            }, 800);
          }, 400);
        }
      }, 800);
    },
    [
      EnimyBattleInfo,
      castSpellAnimation,
      enimyRef,
      hitPlayerEffectRef,
      playerRef,
    ],
  );

  const setStageWithDelay = useCallback((stage: number, delay: number) => {
    setTimeout(() => {
      setCurrectStage(stage);
    }, delay);
  }, []);

  const [nearUserName, setNearUserName] = useState<string>();
  const [isFailedChallange, setIsFailedChallange] = useState(false);

  const onResultCheck = useCallback(
    (data: TestStatusResponseDTO, variables: TestStatusRequestDTO) => {
      if (data.test_1 === true) {
        onQuestionAnswered(true);

        updateAuth.mutate({
          near: variables.near_account_id,
        });

        setNearUserName(variables.near_account_id);
        setCurrectStage(5);
      } else {
        setIsFailedChallange(true);
      }
    },
    [onQuestionAnswered, updateAuth],
  );

  const checkQuest = useCheckQuest({
    onSuccess: (data, variables) => {
      onResultCheck(data, variables);
    },
  });

  const onSubmit = useCallback(
    (data: UsernameCheckChallenge) => {
      checkQuest.mutate({
        near_account_id: data.nearUserName.toLowerCase(),
      });
    },
    [checkQuest],
  );

  useEffect(() => {
    setStageWithDelay(0, 1000);
  }, [setStageWithDelay]);

  if (!getAuth.isSuccess) return <div>Loading</div>;
  return (
    <div className="relative w-full h-full">
      <div
        style={{
          backgroundImage: 'url("/backgrounds/2.jpg")',
          backgroundSize: 'cover',
        }}
        className="absolute top-0 left-0 w-full h-full"
      >
        <Canvas
          shadows
          camera={{rotation: [-0.3, 0, 0], position: [0, 2.3, 5], fov: 60}}
        >
          <GameSound
            ref={fireballSoundRef}
            src="https://storage.googleapis.com/stemmy-integrations/sfx/Fireball.wav"
          />

          <GameSound
            ref={bgFightAudio}
            src="https://storage.googleapis.com/stemmy-integrations/audio/bg_fight.mp3"
            autoplay
            loop
          />
          <GameSound
            ref={bgNormalAudio}
            src="https://storage.googleapis.com/stemmy-integrations/audio/bg_normal.mp3"
            loop
          />

          <GameSound
            ref={successSound}
            src="https://storage.googleapis.com/stemmy-integrations/sfx/answerSuccess.wav"
          />
          <GameSound
            ref={wrongSound}
            src="https://storage.googleapis.com/stemmy-integrations/sfx/answerWrong.mp3"
          />

          <ambientLight color="#fff" intensity={0.3} />
          <directionalLight
            position={[0, 15, 10]}
            intensity={0.5}
            castShadow
            shadow-mapSize-height={512}
            shadow-mapSize-width={512}
          />
          <HitEffect
            ref={hitPlayerEffectRef}
            position={new Vector3(-2.8, 1, 1)}
            audioSrc="https://storage.googleapis.com/stemmy-integrations/sfx/CombatPunch2.wav"
          />
          <HitEffect
            ref={hitEnimyEffectRef}
            position={new Vector3(3, 1, 1)}
            audioSrc="https://storage.googleapis.com/stemmy-integrations/sfx/Punch1.wav"
          />
          <Character
            ref={playerRef}
            scale={new Vector3(1, 1, 1)}
            position={new Vector3(-3.8, -1, 0)}
            rotation={new Euler(0, 1.5, 0)}
            headModel={headModel}
            bodyModel={bodyModel}
            // weaponModel={swordModel}
          />
          <Enimy
            ref={enimyRef}
            scale={new Vector3(1.5, 1.5, 1.5)}
            position={new Vector3(3.8, -1, 0)}
            rotation={new Euler(0, -1.4, 0)}
          />

          {/* to - (3); from - (-2.5) */}
          <animated.group
            position-x={fireballPosition}
            visible={isFireballVisible}
          >
            <Fireball ref={fireballAnimation} />
          </animated.group>

          <mesh
            position={[0, -1, 0]}
            rotation={[Math.PI / 2, 0, 0]}
            receiveShadow
          >
            <planeBufferGeometry attach="geometry" args={[25, 15]} />
            <shadowMaterial
              attach="material"
              side={BackSide}
              color="#000"
              opacity={0.2}
              transparent
            />
          </mesh>
        </Canvas>
      </div>
      <BattleInfo
        avatarSrc="/stemmy/p_head.png"
        orientation={Orientation.LEFT}
        totalHealth={3}
        totalEnergy={10}
        properties={[]}
        ref={PlayerBattleInfo}
      />
      <BattleInfo
        avatarSrc="/stemmy/e_head.png"
        orientation={Orientation.RIGHT}
        totalHealth={4}
        totalEnergy={10}
        properties={[]}
        ref={EnimyBattleInfo}
      />
      {/* =========================================== QUEST =========================================== */}

      {isFailedChallange && (
        <BlackBackground className="z-20">
          <div className="absolute z-30 w-full h-full flex flex-col justify-center items-center">
            <InformingView>
              <div className="flex flex-col gap-3 my-2">
                <div className="font-bold text-game-lg">
                  {t('we-couldnt-find-your-near-account', {ns: 'universal'})}
                </div>
                <div>
                  {t(
                    'enter-the-username-you-have-provided-in-process-of-here-wallet-installation-it-will-usually-look-like-myaccount-near',
                    {ns: 'universal'},
                  )}
                </div>
                <Button onClick={() => setIsFailedChallange(false)}>
                  {t('try-again-0', {ns: 'universal'})}
                </Button>
              </div>
            </InformingView>
          </div>
        </BlackBackground>
      )}

      <GamePopup
        prefixId={prefixId}
        id="intro"
        active={currectStage == 0}
        onFinish={() => setCurrectStage(1)}
        elements={[
          {
            id: 'intro',
            conversationImage: '/stemmy/e_head.png',
            title: t('cool-name'),
            text: t(
              'lets-consolidate-your-knowledge-for-the-future-tournament-and-practice-read-the-question-carefully-and-choose-the-correct-answer',
            ),

            externalbuttons: [
              {
                id: 'start',
                title: t('start-fight'),
                buttonStyle: ButtonStyle.Purple,
                className: 'text-game-xl',
                isNextOnClick: true,
              },
            ],
          },
        ]}
      />

      <GamePopup
        prefixId={prefixId}
        id="test1"
        active={currectStage == 1}
        tempHidden={isQuestionsHidden}
        elements={[
          {
            id: 'task1',
            title: t('difference'),
            windowSize: WidnowSize.Big,
            buttonsLayout: ButtonsLayout.TWO_COLUMNS,

            externalbuttons: [
              {
                id: '1',
                title: t('no-documents'),
                clickDelay: 1000,
                isCoorrectAnswer: false,
                isNextOnClick: true,
                className: 'text-game-lg',
                onClick: () => onQuestionAnswered(false),
              },
              {
                id: '2',
                title: t('transfer-in-any'),
                clickDelay: 1000,
                isCoorrectAnswer: false,
                isNextOnClick: true,
                className: 'text-game-lg',
                onClick: () => onQuestionAnswered(false),
              },
              {
                id: '3',
                title: t('buy-goods'),
                clickDelay: 1000,
                isCoorrectAnswer: false,
                isNextOnClick: true,
                className: 'text-game-lg',
                onClick: () => onQuestionAnswered(false),
              },
              {
                id: '4',
                title: t('you-can-cancel'),
                clickDelay: 1000,
                isCoorrectAnswer: true,
                isNextOnClick: true,
                className: 'text-game-lg',
                onClick: () => onQuestionAnswered(true),
              },
            ],
            // fallback: {
            //   id: '3248B9E2-620B-417E-955F-2E542C06357C',
            //   title: t('no-documents-are-needed'),
            //   nextButton: {
            //     title: t('ok-1'),
            //     className: 'mt-3',
            //   },
            // },
          },
          {
            id: 'task2',
            title: t('How-can-I-regain'),
            windowSize: WidnowSize.Big,
            buttonsLayout: ButtonsLayout.TWO_COLUMNS,

            externalbuttons: [
              {
                id: '1',
                title: t('The-account-cannot-be-restored'),
                clickDelay: 1000,
                isCoorrectAnswer: false,
                isNextOnClick: true,
                className: 'text-game-lg',
                onClick: () => onQuestionAnswered(false),
              },
              {
                id: '2',
                title: t('By-calling-technical-support'),
                clickDelay: 1000,
                isCoorrectAnswer: false,
                isNextOnClick: true,
                className: 'text-game-lg',
                onClick: () => onQuestionAnswered(false),
              },
              {
                id: '3',
                title: t('The-seed-phrase-of-12-words'),
                clickDelay: 1000,
                isCoorrectAnswer: true,
                isNextOnClick: true,
                className: 'text-game-lg',
                onClick: () => onQuestionAnswered(true),
              },
              {
                id: '4',
                title: t('Account-can-be-restored-by-sms'),
                clickDelay: 1000,
                isCoorrectAnswer: false,
                isNextOnClick: true,
                className: 'text-game-lg',
                onClick: () => onQuestionAnswered(false),
              },
            ],
            // fallback: {
            //   id: '071E7F3A-3AC2-4108-87F7-A34858150000',
            //   title: t('Save-a-12-word'),
            //   nextButton: {
            //     title: t('ok-1'),
            //     className: 'mt-3',
            //   },
            // },
          },
          {
            id: 'task3',
            title: t('Can-you-transfer-money'),
            windowSize: WidnowSize.Big,
            buttonsLayout: ButtonsLayout.TWO_COLUMNS,

            externalbuttons: [
              {
                id: '1',
                title: t('Yes-he-get'),
                clickDelay: 1000,
                isCoorrectAnswer: false,
                isNextOnClick: true,
                className: 'text-game-lg',
                onClick: () => {
                  onQuestionAnswered(false, () => setCurrectStage(2));
                },
              },
              {
                id: '2',
                title: t('Yes-but-if-he'),
                clickDelay: 1000,
                isCoorrectAnswer: true,
                isNextOnClick: true,
                className: 'text-game-lg',
                onClick: () => {
                  onQuestionAnswered(true, () => setCurrectStage(2));
                },
              },
              {
                id: '3',
                title: t('Yes-he-can-receive'),
                clickDelay: 1000,
                isCoorrectAnswer: false,
                isNextOnClick: true,
                className: 'text-game-lg',
                onClick: () => {
                  onQuestionAnswered(false, () => setCurrectStage(2));
                },
              },
              {
                id: '4',
                title: t('I-cannot-transfer-money'),
                clickDelay: 1000,
                isCoorrectAnswer: false,
                isNextOnClick: true,
                className: 'text-game-lg',
                onClick: () => {
                  onQuestionAnswered(false, () => setCurrectStage(2));
                },
              },
            ],
          },
        ]}
      />

      <LeaderboardPopup
        active={currectStage === 2}
        prefixId={prefixId}
        id="dummy_leaderboard"
        onStart={() => {
          bgFightAudio.current?.stop();
          bgNormalAudio.current?.play();
        }}
        leaderboard={{
          title: t('Trainig-fight'),
          text: t(
            'good-result-now-i-have-a-quick-task-for-you-it-will-help-you-in-battle',
          ),
          lines: [
            {
              place: 1,
              name: getAuth.data.name,
              customProperties: [
                {
                  value: `${question1CorrectAnswers}/3`,
                },
              ],
            },
          ],
        }}
        onFinish={() => setCurrectStage(3)}
        element={{
          id: 'leaderboard',
          externalbuttons: [
            {
              id: 'next',
              isFinishOnClick: true,
              buttonStyle: ButtonStyle.Purple,
              className: 'text-game-xl',
              title: t('Next'),
            },
          ],
        }}
      />

      <GamePopup
        prefixId={prefixId}
        id="quest1"
        active={currectStage == 3}
        tempHidden={isQuestionsHidden}
        elements={[
          {
            id: 'mobile_device',
            conversationImage: '/stemmy/e_head.png',
            title: t('What-mobile-device'),

            externalbuttons: [
              {
                id: 'Android',
                title: t('Android'),
                buttonStyle: ButtonStyle.Purple,
                className: 'text-game-xl',
                onClick: () => switchScene(10, {isAndroid: true}),
              },
              {
                id: 'IPhone',
                title: t('IPhone'),
                buttonStyle: ButtonStyle.Purple,
                className: 'text-game-xl',
                isNextOnClick: true,
              },
            ],
          },
          {
            id: 'step1',
            conversationImage: '/stemmy/e_head.png',
            title: t('Step-1'),
            horizontalElement: (
              <div>
                {t('download-apple-app-from')}{' '}
                <a
                  href="https://appstore.herewallet.app/site?"
                  target="_blank"
                  className="underline underline-offset-3"
                >
                  {t('apple-store')}
                </a>
              </div>
            ),
            windowSize: WidnowSize.Big,
            buttonsAlignment: ButtonsAlignment.Horizontal,
            innerButtons: [
              {
                id: 'back',
                title: t('Back'),
                isBackOnClick: true,
              },
              {
                id: 'next',
                className: 'flex-1',
                title: t('Next'),
                isNextOnClick: true,
              },
            ],
          },
          {
            id: 'step2',
            conversationImage: '/stemmy/e_head.png',
            title: t('Step-2'),

            verticalElement: (
              <AndroidPhone image="/here/android/quest1/1.jpg" />
            ),
            innerButtons: [
              {
                id: 'back',
                title: t('Back'),
                isBackOnClick: true,
              },
              {
                id: 'next',
                className: 'flex-1',
                title: t('Next'),
                isNextOnClick: true,
              },
            ],
            buttonsAlignment: ButtonsAlignment.Horizontal,
            windowSize: WidnowSize.Big,
          },
          {
            id: 'step3',
            conversationImage: '/stemmy/e_head.png',
            title: t('Step-3'),
            text: t('Notice-that-it'),

            verticalElement: (
              <AndroidPhone image="/here/android/quest1/2.jpg" />
            ),
            innerButtons: [
              {
                id: 'back',
                title: t('Back'),
                isBackOnClick: true,
              },
              {
                id: 'next',
                className: 'flex-1',
                title: t('Next'),
                isNextOnClick: true,
              },
            ],
            buttonsAlignment: ButtonsAlignment.Horizontal,
            windowSize: WidnowSize.Big,
          },
          {
            id: 'step4',
            conversationImage: '/stemmy/e_head.png',
            title: t('Final-Step'),
            text: t('W-will-send'),
            horizontalElement: (
              <form
                className="flex w-full mt-2 gap-2"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Input
                  className="flex-1"
                  registration={{register, errors}}
                  name="nearUserName"
                  options={{
                    required: t('please-enter-your-near-account', {
                      ns: 'universal',
                    }),
                  }}
                />
                <Button type="submit">{t('ok-1')}</Button>
              </form>
            ),
            externalbuttons: [
              {
                id: 'back',
                title: t('Back'),
                isBackOnClick: true,
                buttonStyle: ButtonStyle.Purple,
              },
            ],
          },
        ]}
      />

      <GamePopup
        prefixId={prefixId}
        id="fin"
        tempHidden={isQuestionsHidden}
        active={currectStage == 5}
        elements={[
          {
            id: 'dialog1',
            conversationImage: '/stemmy/e_head.png',
            title: t(
              'excellent-it-remains-to-pick-up-your-weapon-ill-give-you-mine-but-ill-ask-for-a-small-favor',
            ),
            externalbuttons: [
              {
                id: 'next',
                title: t('ok-1'),
                buttonStyle: ButtonStyle.Purple,
                className: 'text-game-xl',
                isNextOnClick: true,
              },
            ],
          },
          {
            id: 'dialog2',
            conversationImage: '/stemmy/e_head.png',
            title: t('Do-you-want-to-set'),

            externalbuttons: [
              {
                id: 'yes',
                title: t('yes'),
                buttonStyle: ButtonStyle.Purple,
                className: 'text-game-xl',
                onClick: () =>
                  switchScene(1, {nearAccount: nearUserName, isAndroid: false}),
              },
              {
                id: 'no',
                title: t('wanna-fight'),
                buttonStyle: ButtonStyle.Purple,
                className: 'text-game-xl',
                onClick: () =>
                  switchScene(2, {nearAccount: nearUserName, isAndroid: false}),
              },
            ],
          },
        ]}
      />
    </div>
  );
};

export default HerePart1;
