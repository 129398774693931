import {useCallback, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import Button, {ButtonStyle} from '../form/button/Button';
import {ButtonSize} from '../buttons/yellowShort/StemmyYellowButton';
import RegistrationInput from './RegistrationInput';
import RegistrationField from './RegistrationField';
import './RegistrationCard.scoped.css';

export interface RegistrationCardProps {
  faceCard?: 'front' | 'back';
  avatar?: 'alien' | 'girl' | 'boy';
  onButtonClicked?: () => void;
}

const RegistrationCard: React.FC<RegistrationCardProps> = ({
  faceCard,
  avatar = 'alien',
  onButtonClicked = () => {},
}) => {
  const [data, setData] = useState({
    name: '',
    gender: '',
    email: '',
    textField1: '',
    textField2: '',
  });

  // тут храним текст ошибки, выводим на аватаром. Если пустая строка, ошибки нет и ничего не показываем над аватаром.
  const [isErrors, setIsErrors] = useState({
    name: 'Name is empty',
    gender: 'Gender is empty',
    email: 'Email is empty',
  });

  const validateEmail = useCallback((email: string) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
  }, []);

  const onRegisterClicked = useCallback(() => {
    console.log('Card data:', data);
  }, [data]);

  const checkDisabled = useCallback(() => {
    if (
      !isErrors.name.trim() &&
      !isErrors.gender.trim() &&
      !isErrors.email.trim()
    ) {
      return false;
    } else {
      return true;
    }
  }, [isErrors.name, isErrors.gender, isErrors.email]);

  return (
    <div className="mb-4 relative h-full aspect-video">
      <div className="registrationCard-spot-1" />
      <div className="registrationCard-spot-2" />
      <div className="registrationCard-spot-3" />

      <AnimatePresence>
        {(faceCard === 'front' || faceCard === undefined) && (
          <motion.div
            id="frontCard"
            key="frontCard"
            initial={faceCard === undefined ? false : {opacity: 0}}
            animate={{opacity: 1, transition: {delay: 1, duration: 0}}}
            exit={{opacity: 0, transition: {delay: 1, duration: 0}}}
          >
            <RegistrationField width="35%" height="35%" top="5%" left="0%" />

            <RegistrationField width="60%" height="79%" top="12%" left="4%">
              <img
                src={`assets/registationCard/${avatar}.png`}
                alt="Avatar"
                className="absolute w-full h-full object-contain avatar-registrationCard"
              />
            </RegistrationField>

            {(isErrors.name || isErrors.gender || isErrors.email) && (
              <RegistrationField width="40%" height="15%" top="5%" left="45%">
                <div className="flex justify-center items-center h-full w-full whitespace-nowrap">
                  {isErrors.name
                    ? isErrors.name
                    : isErrors.email
                    ? isErrors.email
                    : isErrors.gender}
                </div>
              </RegistrationField>
            )}

            <RegistrationInput
              width="40%"
              height="13%"
              top="29%"
              left="57%"
              title="NAME"
              type="text"
              value={data.name}
              onChange={(text) => {
                if (!text.trim()) {
                  setIsErrors((old) => ({...old, name: 'Name is empty'}));
                } else {
                  setIsErrors((old) => ({...old, name: ''}));
                }

                setData((old) => {
                  return {
                    ...old,
                    name: text,
                  };
                });
              }}
            />
            <RegistrationInput
              width="40%"
              height="13%"
              top="44%"
              left="55%"
              title="GENDER"
              type="text"
              value={data.gender}
              onChange={(text) => {
                if (!text.trim()) {
                  setIsErrors((old) => ({...old, gender: 'Gender is empty'}));
                } else {
                  setIsErrors((old) => ({...old, gender: ''}));
                }

                setData((old) => {
                  return {
                    ...old,
                    gender: text,
                  };
                });
              }}
            />
            <RegistrationInput
              width="40%"
              height="13%"
              top="59%"
              left="53%"
              title="EMAIL"
              type="email"
              value={data.email}
              onChange={(text) => {
                if (validateEmail(text)) {
                  setIsErrors((old) => ({...old, email: ''}));
                } else {
                  setIsErrors((old) => ({...old, email: 'Email error'}));
                }
                if (!text.trim()) {
                  setIsErrors((old) => ({...old, email: 'Email is empty'}));
                }

                setData((old) => {
                  return {
                    ...old,
                    email: text,
                  };
                });
              }}
            />

            <div className="absolute signature-registrationCard w-[25%] h-[23%] top-[78%] left-[50%]" />

            <div className="absolute bottom-0 right-0">
              <Button
                buttonStyle={ButtonStyle.Green}
                btnSize={ButtonSize.SMALL}
                onClick={onButtonClicked}
              >
                Turn
              </Button>
            </div>
          </motion.div>
        )}

        {faceCard === 'back' && (
          <motion.div
            id="backCard"
            key="backCard"
            initial={{opacity: 0}}
            animate={{opacity: 1, transition: {delay: 1, duration: 0}}}
            exit={{opacity: 0, transition: {delay: 1, duration: 0}}}
          >
            <RegistrationInput
              width="40%"
              height="13%"
              top="5%"
              left="0%"
              title="TEXT FIELD"
              type="text"
              value={data.textField1}
              onChange={(text) =>
                setData((old) => {
                  return {
                    ...old,
                    textField1: text,
                  };
                })
              }
            />
            <RegistrationInput
              width="40%"
              height="13%"
              top="5%"
              left="45%"
              title="TEXT FIELD"
              type="text"
              value={data.textField2}
              onChange={(text) =>
                setData((old) => {
                  return {
                    ...old,
                    textField2: text,
                  };
                })
              }
            />

            <div className="absolute bottom-0 right-0">
              <Button
                buttonStyle={ButtonStyle.Green}
                btnSize={ButtonSize.SMALL}
                onClick={onButtonClicked}
              >
                Turn
              </Button>
            </div>

            <div className="absolute bottom-0 left-0">
              <Button
                disabled={checkDisabled()}
                buttonStyle={
                  checkDisabled() ? ButtonStyle.Red : ButtonStyle.Yellow
                }
                btnSize={ButtonSize.BASE}
                onClick={onRegisterClicked}
              >
                Register
              </Button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* <div className="flex flex-col w-full">
        <h1 className="font-sigmarOne text-game-lg lg:pb-2">{title}</h1>
        <h2 className="font-roboto text-game-sm font-bold leaderboard-text-shadow pb-3 lg:pb-10">
          {text}
        </h2>
      </div> */}
    </div>
  );
};
export default RegistrationCard;
