import {useGLTF} from '@react-three/drei';
import {useEffect, useRef, useState} from 'react';
import * as THREE from 'three';
import {ModelTransform} from '../../../types/models/BasicTypes';
import {JetpackWithRigBaseModel} from '../../../types/models/v2/bases/JetpackWithRigBase';
import {BasicBodyModel} from '../../../types/models/v2/bodies/BasicBody';
import {BasicHeadModel} from '../../../types/models/v2/heads/BasicHead';

interface JetpackCharacterProps extends ModelTransform {
  headModel?: BasicHeadModel;
  bodyModel?: BasicBodyModel;

  onLoaded?: (group: THREE.Group) => void;
}

const JetpackCharacter: React.FC<JetpackCharacterProps> = ({
  onLoaded,
  headModel,
  bodyModel,
  position,
  rotation,
  scale,
}) => {
  const group = useRef<THREE.Group>() as React.MutableRefObject<THREE.Group>;
  const {nodes, materials} = useGLTF(
    '/models/v2/bases/JetPackRigWithEmptyObjects.glb',
  ) as unknown as JetpackWithRigBaseModel;

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (isLoaded) return;

    if (group.current) {
      setIsLoaded(true);
      if (onLoaded) onLoaded(group.current);
    }
  }, [group, isLoaded, onLoaded]);

  return (
    <group
      ref={group}
      dispose={null}
      position={position}
      scale={scale}
      rotation={rotation}
    >
      <primitive object={nodes.mixamorigHips} />
      <skinnedMesh
        geometry={
          bodyModel ? bodyModel.nodes.BODY.geometry : nodes.EMPTYBody.geometry
        }
        material={
          bodyModel ? bodyModel.nodes.BODY.material : nodes.EMPTYBody.material
        }
        skeleton={nodes.EMPTYBody.skeleton}
      />
      <skinnedMesh
        geometry={
          headModel ? headModel.nodes.HEAD.geometry : nodes.EMPTYHead.geometry
        }
        material={
          headModel ? headModel.nodes.HEAD.material : nodes.EMPTYHead.material
        }
        skeleton={nodes.EMPTYHead.skeleton}
      />
      <skinnedMesh
        geometry={nodes.JetPack.geometry}
        material={materials.Palete}
        skeleton={nodes.JetPack.skeleton}
      />
    </group>
  );
};

export default JetpackCharacter;
