import React from 'react';
import {motion} from 'framer-motion';
import {divVariants} from '../../styles/framerMotionVariants';
import './StemmyTextField.scoped.css';

export interface StemmyTextFieldProps {
  question?: string;
}

const StemmyTextField: React.FC<StemmyTextFieldProps> = ({question}) => {
  return (
    <motion.div
      initial={'hidden'}
      animate={'visible'}
      transition={{duration: 0.25}}
      variants={divVariants}
      className="w-full rounded-lg px-5 md:px-14 py-4 md:py-10 bg-purple-gradient text-white text-center cursor-default mx-auto font-roboto font-bold relative text-game-lg"
    >
      {question}
    </motion.div>
  );
};
export default StemmyTextField;
