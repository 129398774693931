import {AnimatePresence, motion} from 'framer-motion';
import {useCallback} from 'react';
import {divVariants} from '../../styles/framerMotionVariants';
import ButtonsList from '../mechanics/InformingSliderView/ButtonsList';
import {PopupButton} from '../mechanics/InformingSliderView/GamePopupElement';
import './DialogBubble.scoped.css';

export enum DialogBubbleAnimation {
  Initial,
  Back,
  Next,
  Hidden,
}

interface DialogBubbleProps {
  animation?: number;
  children?: React.ReactNode;
  elementId?: string;
  question?: string;
  buttons?: PopupButton[];
  prefixId: string;
  onBack: () => void;
  onNext: () => void;
  onFinish: () => void;
  onFallback: () => void;
  onJumpToSlide: (val: number) => void;
}

const DialogBubble: React.FC<DialogBubbleProps> = ({
  elementId,
  children,
  animation,
  question = '',
  buttons,
  onBack,
  onNext,
  onFinish,
  onFallback,
  onJumpToSlide,
  ...rest
}) => {
  const getAnimationName = useCallback(() => {
    if (animation === DialogBubbleAnimation.Hidden) return 'hidden';
    if (animation === DialogBubbleAnimation.Back) return 'back';
    if (animation === DialogBubbleAnimation.Next) return 'next';
    return 'visible';
  }, [animation]);

  return (
    <AnimatePresence>
      <motion.div
        id={elementId}
        // className="absolute top-[2%] left-1/2 lg:right-1/3"
        initial={'hidden'}
        animate={getAnimationName()}
        transition={{duration: 0.25}}
        variants={divVariants}
        exit={'exit'}
        {...rest}
      >
        <div className="relative px-4 lg:px-8 py-2 lg:py-4 rounded-lg bg-[#8444EE] speech-bubble text-white font-bold">
          <span className="text-game-2xl mb-3 lg:mb-5">{question}</span>
          {buttons && (
            <div className="mt-2 flex justify-center gap-2 items-center text-game-xl">
              <ButtonsList
                prefixId="dialogButtonsId"
                buttons={buttons}
                onFinish={onFinish}
                onFallback={onFallback}
                onBack={onBack}
                onNext={onNext}
                onJumpToSlide={onJumpToSlide}
              />
            </div>
          )}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default DialogBubble;
