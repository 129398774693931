import React, {useState} from 'react';
import StemmyInformingField from '../../textField/informing/StemmyInformingField';
import StemmyYellowButton from '../../buttons/yellowShort/StemmyYellowButton';
import './InformingView.scoped.css';

export interface InformingPage {
  onBackClicked?: () => void;
  onForwardClicked?: () => void;

  customButtonTitle?: string;
  onCustomFunctionClick?: () => void;
  hasFinishButton?: boolean;
  children: React.ReactNode;
}

const StemmyInformingView: React.FC<InformingPage> = ({
  onForwardClicked,
  onBackClicked,
  onCustomFunctionClick,
  customButtonTitle,
  hasFinishButton,
  children,
}) => {
  const [animation, setAnimation] = useState('initial');

  return (
    <div className="h-full w-full">
      <StemmyInformingField animation={animation}>
        <div className="text-game-base">{children}</div>

        <div className="flex gap-2 mt-2">
          {onBackClicked && (
            <StemmyYellowButton
              answer="Back"
              onClick={() => {
                setAnimation('back');
                onBackClicked();
              }}
            />
          )}
          {onForwardClicked && (
            <StemmyYellowButton
              answer="Next"
              onClick={() => {
                setAnimation('next');
                onForwardClicked();
              }}
            />
          )}
          {hasFinishButton &&
            !onForwardClicked &&
            onCustomFunctionClick &&
            customButtonTitle && (
              <StemmyYellowButton
                answer={customButtonTitle}
                onClick={() => {
                  setAnimation('next');
                  onCustomFunctionClick();
                }}
              />
            )}
        </div>
      </StemmyInformingField>
    </div>
  );
};

export default StemmyInformingView;
