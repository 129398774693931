import {useAnimations} from '@react-three/drei';
import {
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import * as THREE from 'three';
import {AnimationAction, AnimationClip} from 'three';
import {DefaultFadeAnimation} from './AnimationFadePrefabs';
import {LoopAnimation} from './AnimationRepeatPrefabs';

type AnimationFinished = THREE.Event & {
  type: 'finished';
} & {
  target: THREE.AnimationMixer;
  action?: AnimationAction;
};

export interface AnimationRepeatData {
  mode: THREE.AnimationActionLoopStyles;
  repeats: number;
}

export interface AnimationFadeData {
  fadeInDuration: number;
  fadeOutDuration: number;
  isReset: boolean;
}

interface AnimationLoaderProps<T extends AnimationClip> {
  group?: THREE.Group;
  animations: T[];

  onAnimationFinished?: (animationName: T['name']) => void;
  onAnimationStarted?: (animationName: T['name']) => void;
}

export interface AnimationLoaderRefProps<T extends AnimationClip> {
  animate: (
    element: T['name'],
    repeatMode?: AnimationRepeatData,
    fadeMode?: AnimationFadeData,
    clampWhenFinished?: boolean,
  ) => void;
}

const AnimationLoader = <T extends AnimationClip>(
  props: AnimationLoaderProps<T> & {myRef: Ref<AnimationLoaderRefProps<T>>},
) => {
  const {actions, mixer} = useAnimations(props.animations, props.group);

  const [activeAction, setActiveAction] = useState<THREE.AnimationAction>();
  const fadeAction = useCallback(
    (
      toAction: THREE.AnimationAction,
      fadeIn: number,
      fadeOut: number,
      isReset: boolean,
    ) => {
      if (!toAction) return;
      if (toAction != activeAction) {
        if (activeAction) {
          activeAction.fadeOut(fadeOut);

          if (
            props.onAnimationFinished &&
            activeAction.loop !== THREE.LoopOnce
          ) {
            setTimeout(() => {
              if (props.onAnimationFinished)
                props.onAnimationFinished(activeAction.getClip().name);
            }, fadeOut);
          }
        }

        if (isReset) {
          toAction.reset();
        }

        toAction.fadeIn(fadeIn);
        toAction.play();

        if (props.onAnimationStarted) {
          setTimeout(() => {
            if (props.onAnimationStarted)
              props.onAnimationStarted(toAction.getClip().name);
          }, fadeIn);
        }

        setActiveAction(toAction);
      } else {
        activeAction.reset().play();
      }
    },
    [activeAction, props],
  );

  const onAnimationFinished = useCallback(
    (e: AnimationFinished) => {
      const animationName = e.action?.getClip().name;
      if (!animationName || !props.onAnimationFinished) return;

      props.onAnimationFinished(animationName);
    },
    [props],
  );

  useEffect(() => {
    mixer.addEventListener('finished', onAnimationFinished);

    return () => mixer.removeEventListener('finished', onAnimationFinished);
  }, [mixer, onAnimationFinished]);

  useImperativeHandle(props.myRef, () => ({
    animate(
      element,
      repeatMode = LoopAnimation,
      fadeMode = DefaultFadeAnimation,
      clampWhenFinished = true,
    ) {
      const currentAnimation = actions[element];
      if (!currentAnimation) return;

      currentAnimation.clampWhenFinished = clampWhenFinished;
      currentAnimation.setLoop(repeatMode.mode, repeatMode.repeats);

      fadeAction(
        currentAnimation,
        fadeMode.fadeInDuration,
        fadeMode.fadeOutDuration,
        fadeMode.isReset,
      );
    },
  }));

  return <></>;
};

export default AnimationLoader;
