import {Loader} from '@react-three/drei';
import {useEffect, useState} from 'react';
import {
  useGameAuthData,
  useSetGameAuthData,
} from '../../services/gameAuth/AuthProvider';
import {HereIntegrationSave} from '../../types/save/HereIntegrationSave';
import HereIntegrastionScene from './HereScenario';

interface IntegrationDemoWrapperProps {
  loadData: HereIntegrationSave;
}

const IntegrationDemoWrapper: React.FC<IntegrationDemoWrapperProps> = ({
  loadData,
}) => {
  const [isAuthorised, setIsAuthorised] = useState(false);
  useEffect(() => {}, []);

  const setAuth = useSetGameAuthData({
    onSuccess: () => {
      setIsAuthorised(true);
    },
  });

  const getAuth = useGameAuthData({
    onSuccess: () => {
      setIsAuthorised(true);
    },
    onError: () => {
      setAuth.mutate({
        id: '29c26d13-1466-4f5c-b427-0bc2b8c0cc93',
        name: 'den.mko',
      });
    },
  });

  if (!getAuth.isSuccess) return <Loader />;
  return <HereIntegrastionScene loadData={loadData} />;
};

export default IntegrationDemoWrapper;
