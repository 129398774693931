import StemmyYellowAnswer from '../../components/answers/StemmyYellowAnswer';
import StemmyPurpleButton from '../../components/buttons/purpleLong/StemmyPurpleButton';
import StemmyYellowButton from '../../components/buttons/yellowShort/StemmyYellowButton';
import InfoPopupAlert from '../../components/infoPopap/InfoPopap';
import StemmyDialogView from '../../components/stemmy/dialogView/DialogView';
import StemmyInformingView from '../../components/stemmy/informingView/InformingView';
import StemmyInformingField from '../../components/textField/informing/StemmyInformingField';
import StemmyTextField from '../../components/textField/purple/StemmyTextField';
import StemmyTextFieldWithHead from '../../components/textField/purpleWithHead/StemmyTextFieldWithHead';
import Button, {ButtonStyle} from '../../components/form/button/Button';
import LeaderboardPopup from '../../components/game/mechanics/leaderboardPopup/LeaderboardPopup';
import BlackBackground from '../../components/game/utils/BlackBackground';
import RegistrationCard from '../../components/registrationCard/RegistrationCard';
import {motion} from 'framer-motion';
import RegistrationPopup from '../../components/game/mechanics/registrationPopup/RegistrationPopup';

const UiExamples: React.FC = () => {
  return (
    <div
      className="w-full h-full relative overflow-hidden text-game-base"
      style={{
        backgroundColor: '#000',
        backgroundSize: 'cover',
      }}
    >
      <h1 className="text-white flex justify-center">UI examples</h1>
      <div className="grid grid-cols-2 gap-y-2 lg:gap-y-4 gap-x-2 lg:gap-x-8 items-center justify-items-center bg-slate-900">
        <div className="text-white">1) StemmyYellowAnswer:</div>
        <StemmyYellowAnswer answer={{text: 'Test', id: 'test'}} />
      </div>
      <div className="grid grid-cols-2 gap-y-2 lg:gap-y-4 gap-x-2 lg:gap-x-8 items-center justify-items-center bg-slate-900 mt-2">
        <div className="text-white">2) StemmyPurpleButton:</div>
        <StemmyPurpleButton
          answer={{
            answer: 'Test',
            answerId: 'testads',
          }}
          onClick={() => {}}
        />
      </div>
      <div className="grid grid-cols-2 gap-y-2 lg:gap-y-4 gap-x-2 lg:gap-x-8 items-center justify-items-center bg-slate-900 mt-2">
        <div className="text-white">3) StemmyYellowButton:</div>
        <StemmyYellowButton answer="Test" />
      </div>
      <div className="grid grid-cols-2 gap-y-2 lg:gap-y-4 gap-x-2 lg:gap-x-8 items-center justify-items-center bg-slate-900 mt-2">
        <div className="text-white">4) Form Buttons:</div>
        <div className="flex gap-2">
          <Button buttonStyle={ButtonStyle.Yellow}>Test</Button>
          <Button buttonStyle={ButtonStyle.Purple}>Test</Button>
          <Button buttonStyle={ButtonStyle.Green}>Test</Button>
          <Button buttonStyle={ButtonStyle.Red}>Test</Button>
          <Button buttonStyle={ButtonStyle.Blue}>Test</Button>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-y-2 lg:gap-y-4 gap-x-2 lg:gap-x-8 items-center justify-items-center bg-slate-900 mt-2">
        <div className="text-white">5) InfoPopupAlert:</div>
        <div className="text-white relative">
          <InfoPopupAlert>Test</InfoPopupAlert>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-y-2 lg:gap-y-4 gap-x-2 lg:gap-x-8 items-center justify-items-center bg-slate-900 mt-2">
        <div className="text-white">6) StemmyDialogView:</div>
        <StemmyDialogView
          dialog={{
            question: 'Test question',
            answers: [
              {answer: 'test1', answerId: 'sdff'},
              {answer: 'test2', answerId: 'sdff2'},
              {answer: 'test3', answerId: 'sdff3'},
            ],
            onClicked: () => {},
          }}
          isShuffleAnswers={false}
          onDialogAnswered={() => {}}
        />
      </div>
      <div className="grid grid-cols-2 gap-y-2 lg:gap-y-4 gap-x-2 lg:gap-x-8 items-center justify-items-center bg-slate-900 mt-2">
        <div className="text-white">7) StemmyInformingView:</div>
        <StemmyInformingView>Test</StemmyInformingView>
      </div>
      <div className="grid grid-cols-2 gap-y-2 lg:gap-y-4 gap-x-2 lg:gap-x-8 items-center justify-items-center bg-slate-900 mt-2">
        <div className="text-white">8) StemmyInformingField:</div>
        <StemmyInformingField>Test</StemmyInformingField>
      </div>
      <div className="grid grid-cols-2 gap-y-2 lg:gap-y-4 gap-x-2 lg:gap-x-8 items-center justify-items-center bg-slate-900 mt-2">
        <div className="text-white">9) StemmyTextField:</div>
        <StemmyTextField question="Test" />
      </div>
      <div className="grid grid-cols-2 gap-y-2 lg:gap-y-4 gap-x-2 lg:gap-x-8 items-center justify-items-center bg-slate-900 mt-2">
        <div className="text-white">10) StemmyTextFieldWithHead:</div>
        <StemmyTextFieldWithHead question="Test" />
      </div>
      <div className="flex gap-y-2 lg:gap-y-4 gap-x-2 lg:gap-x-8 items-center justify-items-center bg-slate-900 mt-2">
        <div className="text-white">11) LeaderboardPopup:</div>
        <div className="relative w-full">
          <LeaderboardPopup
            active={true}
            prefixId="asddasas"
            id="dummy_leaderboard"
            leaderboard={{
              title: 'Test',
              text: 'text',
              lines: [
                {
                  place: 1,
                  name: 'name',
                  customProperties: [
                    {
                      value: '4',
                    },
                  ],
                },
              ],
            }}
            element={{
              id: '3554364256543543',
              externalbuttons: [
                {
                  id: 'next',
                  isFinishOnClick: true,
                  buttonStyle: ButtonStyle.Purple,
                  className: 'text-game-xl',
                  title: 'next',
                },
              ],
            }}
          />
        </div>
      </div>

      <div className="flex gap-y-2 lg:gap-y-4 gap-x-2 lg:gap-x-8 items-center justify-items-center bg-slate-900 mt-2">
        <div className="text-white w-1/3">12) RegistrationPopup:</div>
        <RegistrationPopup />
      </div>
    </div>
  );
};

export default UiExamples;
