import React from 'react';
import classNames from 'classnames';
import {motion} from 'framer-motion';
import {divVariants} from '../styles/framerMotionVariants';
import {Answer} from '../../pages/questions/GameQuestion';
import './StemmyYellowAnswer.scoped.css';

export interface StemmyYellowAnswerProps {
  answer: Answer;
  isAnswerDone?: boolean;
  isSelected?: boolean;
  isCorrectAnswer?: boolean;
  onClick?: (answer: Answer) => void;
}

const StemmyYellowAnswer: React.FC<StemmyYellowAnswerProps> = ({
  answer,
  isAnswerDone = false,
  isCorrectAnswer = false,
  isSelected = false,
  onClick,
}) => {
  return (
    <motion.button
      initial={'hidden'}
      animate={'visible'}
      transition={{duration: 0.25}}
      variants={divVariants}
      disabled={isAnswerDone}
      className={classNames(
        'w-full h-full px-1 lg:px-3 py-1 md:text-xl text-white font-bold text-center rounded-md cursor-pointer sm:min-h-[64px] flex justify-center items-center',
        !isAnswerDone &&
          'bg-gradient-to-t from-yellow-600 via-yellow-500 to-yellow-500 hover:from-yellow-700 hover:via-yellow-600 hover:to-yellow-500',
        isAnswerDone && isCorrectAnswer && 'correct-answer',
        isAnswerDone && !isCorrectAnswer && 'wrong-answer',
        isAnswerDone &&
          isSelected &&
          isCorrectAnswer &&
          'correct-answer-shadow',
        isAnswerDone && isSelected && !isCorrectAnswer && 'wrong-answer-shadow',
      )}
      onClick={onClick ? () => onClick(answer) : () => {}}
    >
      <span className="z-10 webkit-box-max-2-line text-game-lg">
        {answer.text}
      </span>
    </motion.button>
  );
};
export default StemmyYellowAnswer;
