import './RegistrationCard.scoped.css';

export interface RegistrationFieldProps {
  width?: string;
  height?: string;
  top?: string;
  left?: string;
  children?: React.ReactNode;
}

const RegistrationField: React.FC<RegistrationFieldProps> = ({
  width = '10%',
  height = '10%',
  top = '10%',
  left = '10%',
  children,
}) => {
  return (
    <div
      className={`absolute purple-bg-registrationCard-element w-[${width}] h-[${height}] top-[${top}] left-[${left}]`}
    >
      {children}
    </div>
  );
};
export default RegistrationField;
