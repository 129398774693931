import {motion} from 'framer-motion';
import {useState} from 'react';
import RegistrationCard from '../../../registrationCard/RegistrationCard';
import BlackBackground from '../../utils/BlackBackground';

interface RegistrationPopupProps {}

const RegistrationPopup: React.FC<RegistrationPopupProps> = ({}) => {
  const [faceCard, setFaceCard] = useState<'front' | 'back' | undefined>();

  return (
    <div className="relative w-full aspect-video">
      <BlackBackground>
        <div className="flex flex-col justify-center items-center w-full h-full relative">
          <motion.div
            initial={{rotateY: 0, rotateZ: 0}}
            animate={{
              rotateY:
                faceCard === undefined
                  ? 0
                  : faceCard === 'front'
                  ? [0, -90, 0]
                  : [0, 90, 0],
              rotateZ:
                faceCard === undefined
                  ? 0
                  : faceCard === 'front'
                  ? [0, 5, 0]
                  : [0, -5, 0],
              scale: faceCard === undefined ? [0.9, 1.1, 1] : 1,
            }}
            transition={{duration: faceCard === undefined ? 0.5 : 2}}
            className="w-3/4 max-w-[75%] overflow-hidden"
          >
            <div className="bg-purple-gradient text-white rounded-lg px-4 sm:px-14 py-1 lg:py-4 relative mx-auto">
              <div className="flex gap-12 h-full">
                <div className="flex-1 flex flex-col gap-2 pt-2 lg:pt-4 pb-2 lg:pb-4">
                  <div className="flex-1">
                    <RegistrationCard
                      faceCard={faceCard}
                      avatar="alien"
                      onButtonClicked={() =>
                        setFaceCard(
                          faceCard === undefined
                            ? 'back'
                            : faceCard === 'front'
                            ? 'back'
                            : 'front',
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </BlackBackground>
    </div>
  );
};

export default RegistrationPopup;
